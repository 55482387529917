import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { TravelSimulator } from "./routes/Travelsimulator";
import { Arkheimstories } from "./routes/Arkheimstories";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="travelsimulator" element={<TravelSimulator />} />
      <Route path="arkheimstories" element={<Arkheimstories />} />
    </Routes>
  </BrowserRouter>
);
