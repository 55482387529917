const DiscordSection = () => {
  return (
    <section className="container py-6 mx-auto mb-12 text-center">
      <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-white md:text-5xl">
        Join our Discord community!
      </h2>
      <div className="w-full mb-4">
        <div className="w-1/6 h-1 py-0 mx-auto my-0 bg-white rounded-t opacity-25"></div>
      </div>
      <h3 className="my-4 text-2xl leading-tight md:text-3xl">What are you waiting for?</h3>
      <a
        href="https://discord.gg/248683BK"
        target="_blank"
        className="inline-block px-8 py-4 mx-auto my-6 font-bold text-gray-800 transition duration-300 ease-in-out transform bg-white rounded-full shadow-lg lg:mx-0 hover:underline focus:outline-none focus:shadow-outline hover:scale-105"
        rel="noreferrer"
      >
        Discord
      </a>
    </section>
  );
};

export { DiscordSection };