import { useState } from "react";
import { Layout } from "../../components/Layout";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";

const Arkheimstories = () => {

  return (
    <Layout className="h-screen">
      <section className="h-full py-4 text-black bg-white border-b md:py-8">
        <div className="container flex flex-col items-center justify-center max-w-5xl m-8 mx-auto">
          <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800 md:text-5xl">
            Arkheim Stories
          </h2>
          <div className="w-full mb-4">
            <div className="w-64 h-1 py-0 mx-auto my-0 rounded-t opacity-25 gradient"></div>
          </div>
        </div>
      </section>
      <section>
        
      </section>
    </Layout>
  );
};

export { Arkheimstories };
