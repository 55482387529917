import { useState } from "react";
import { Layout } from "../../components/Layout";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";

const TravelSimulator = () => {
  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  const [totalTime, setTotalTime] = useState(null);

  const missionTypes = [
    { key: "Support", value: "support" },
    { key: "Raid", value: "raid" },
    { key: "Conquer", value: "conquer" },
    { key: "Gate/Fort/Supply", value: "gate" },
    { key: "Goblin Tower", value: "goblin" },
    { key: "Joining", value: "joining" },
  ];

  const delays = {
    support: 10,
    raid: 16,
    conquer: 20,
    gate: 0,
    goblin: 5,
  };

  const handleReset = () => {
    reset();
    setTotalTime(null);
  };

  const calculateTravelTime = ({ data }) => {
    const { x1, y1, x2, y2, missiontype, warlordspeed } = data || {};
    const delay = delays[missiontype];
    const time =
      (Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2) + delay) / warlordspeed;
    const h = Math.trunc(time);
    const m = (time - h) * 60;
    const tm = Math.trunc(m);
    const s = (m - tm) * 60;
    const ts = Math.trunc(s);
    let totalTime = `${h} hours ${tm} minutes ${ts} seconds`;

    if (h === "Infinity" || m === "NaN" || s === "NaN") totalTime = "Missing informations";

    if (h === 0 && tm === 0 && ts === 0) totalTime = "Oh wow! You are faster than light! 🤪";

    return totalTime;
  };

  const onSubmit = (data) => {
    setTotalTime(calculateTravelTime({ data }));
  };

  return (
    <Layout className="h-screen">
      <section className="h-full py-4 text-black bg-white border-b md:py-8">
        <div className="container flex flex-col items-center justify-center max-w-5xl m-8 mx-auto">
          <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800 md:text-5xl">
            Travel simulator
          </h2>
          <div className="w-full mb-4">
            <div className="w-64 h-1 py-0 mx-auto my-0 rounded-t opacity-25 gradient"></div>
          </div>
          <div className="flex justify-center mt-10 md:mt-20">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <Input
                  register={register}
                  id="x1"
                  label="X1"
                  type="number"
                  placeholder="X1"
                  required
                />
                <Input
                  register={register}
                  id="y1"
                  label="Y1"
                  type="number"
                  placeholder="Y1"
                  required
                />
                <Input
                  register={register}
                  id="x2"
                  label="X2"
                  type="number"
                  placeholder="X2"
                  required
                />
                <Input
                  register={register}
                  id="y2"
                  label="Y2"
                  type="number"
                  placeholder="Y2"
                  required
                />
                <Input
                  register={register}
                  id="warlordspeed"
                  label="Warlord speed"
                  type="number"
                  placeholder="Warlord speed"
                  required
                />
                <Select
                  {...register("missiontype")}
                  id="missiontype"
                  label="Mission type"
                  options={missionTypes}
                />
              </div>
              <button
                type="submit"
                className="my-4 md:my-0 mr-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Submit
              </button>
              <button
                onClick={() => handleReset()}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Reset
              </button>
            </form>
          </div>
          <div className="m-5">
            {totalTime && <span className="text-2xl"><b>Total Time:</b> {totalTime}</span>}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export { TravelSimulator };
