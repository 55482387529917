const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="container px-8 mx-auto">
        <div className="flex items-center justify-center w-full py-3 text-black md:flex-row">
          &copy; Arkheim Tools
        </div>
      </div>
    </footer>
  );
};

export { Footer };