import { Nav } from "../Nav";
import { Footer } from "../Footer";

interface LayoutProps {
  children: JSX.Element | JSX.Element[],
  className?: string
};

const Layout = ({ children, className }: LayoutProps) => {
  return (
    <div className={`${className ? className : ''} font-sans leading-normal tracking-normal text-white gradient pancake`}>
      <Nav />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export { Layout };
