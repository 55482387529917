const Input = ({ id, label, type, register, placeholder, required = false }) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-bold text-black"
      >
        {`${required ? '*' : ''} ${label}:`}
      </label>
      <input
        name={id}
        type={type}
        id={id}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 placeholder-black dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder={placeholder}
        required={required}
        {...register(id)}
      />
    </div>
  );
};

export { Input };
