import { Link } from "react-router-dom";

const ToolsSection = () => {
  return (
    <section className="py-8 bg-white border-b">
      <div className="container max-w-5xl m-8 mx-auto">
        <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
          Tools to use
        </h2>
        <div className="w-full mb-4">
          <div className="w-64 h-1 py-0 mx-auto my-0 rounded-t opacity-25 gradient"></div>
        </div>
        <div className="flex flex-wrap">
          <div className="p-6 py-2 w-6/6 md:p-6 sm:w-1/2">
            <h3 className="mb-3 text-3xl font-bold leading-none text-gray-800">
              Travel simulator
            </h3>
            <p className="mb-8 text-gray-600">
              Travel simulator can help you simulate travel time based on
              warlord speed and mission type.
            </p>
          </div>
          <div className="w-full p-2 text-center md:p-6 sm:w-2/2">
            <Link
              className="inline-block px-8 py-4 mx-auto my-3 font-bold text-gray-800 transition duration-300 ease-in-out transform bg-white border-2 rounded-full shadow-lg md:my-6 lg:mx-0 hover:underline focus:outline-none focus:shadow-outline hover:scale-105"
              to="/travelsimulator"
            >
              Try it out
            </Link>
          </div>
        </div>
        <div className="flex flex-col-reverse flex-wrap sm:flex-row">
          <div className="w-full p-3 mt-6 md:p-6 sm:w-2/2">
            <div className="align-middle">
              <h3 className="mb-3 text-3xl font-bold leading-none text-gray-800">
                Arkheim Stories
              </h3>
              <p className="mb-4 text-gray-600 md:mb-8">
                A place where stories are told...
              </p>
            </div>
            <div className="w-full p-2 text-center md:p-6 sm:w-2/2">
            <Link
              className="inline-block px-8 py-4 mx-auto my-3 font-bold text-gray-800 transition duration-300 ease-in-out transform bg-white border-2 rounded-full shadow-lg md:my-6 lg:mx-0 hover:underline focus:outline-none focus:shadow-outline hover:scale-105"
              to="/Arkheimstories"
            >
              Start reading
            </Link>
          </div>
          </div>
        </div>
        <div className="flex flex-col-reverse flex-wrap sm:flex-row">
          <div className="w-full p-3 mt-6 md:p-6 sm:w-2/2">
            <div className="align-middle">
              <h3 className="mb-3 text-3xl font-bold leading-none text-gray-800">
                Battle simulator (WIP)
              </h3>
              <p className="mb-4 text-gray-600 md:mb-8">
                Battle simulator will help you to simulate battles.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { ToolsSection };
