import { forwardRef } from 'react';

const Select = forwardRef(({ id, options, label, required = false }, ref) => {
  return (
    <div>
      <label htmlFor={id} className="block mb-2 text-sm font-bold text-black">
        {`${required ? "*" : ""} ${label}:`}
      </label>
      <select
        ref={ref}
        id="countries"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
        {options &&
          options.map((optionItem, index) => (
            <option value={optionItem.value} key={index}>
              {optionItem.key}
            </option>
          ))}
      </select>
    </div>
  );
});

export { Select };
