import { Hero } from "./components/Hero";
import { ToolsSection } from "./components/ToolsSection";
import { DiscordSection } from "./components/DiscordSection";
import { Layout } from "./components/Layout";

function App() {
  return (
    <Layout>
      <Hero />
      <ToolsSection />
      <DiscordSection />
    </Layout>
  );
}

export default App;
